<template>
  <MainLayout>
  <div class="flex flex-col">
    <div class="container-whole">
      <NavBar class="navbar-responsive"/>
      <h1 v-if="!errorMessage" class="font-poppins font-bold text-dark-blue text-3xl mt-10 ml-5">Performance Summary</h1>
      <h2 v-if="!errorMessage" class="font-poppins font-bold text-gold text-2xl mt-10 ml-5">
        SA Solutions (ZAR)
        <span class="text-dark-blue text-sm font-light ml-6">{{ SA_SolutionDataDaily ? `${day_SA} ${month_SA} ${year_SA}` : 'No data' }}</span>
      </h2>

      <div>
        <h3 v-if="errorMessage" class="font-poppins text-center font-extrabold text-gold mt-14">No data is available at the moment.</h3>
      </div>

      <img v-if="!errorMessage" class="float-right mt-6 w-2/5 hide-image" src="../../public/img/Page4_img.png" alt="">
      
      <div  class="w-1/2 content-table content-container">
        <div v-if="!errorMessage" class="table-container">
          <table class="w-full font-poppins font-semibold text-xs text-center ml-5 mt-6">
            <thead class="text-xs uppercase bg-dark-blue text-white">
              <tr>
                <th scope="col" class="px-6 py-3">
                  
                </th>
                <th scope="col" class="px-6 py-3">
                  Since Inception
                </th>
                <th scope="col" class="px-6 py-3">
                  Monthly
                </th>
                <th scope="col" class="px-6 py-3">
                  3 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  6 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  YTD
                </th>
                <th scope="col" class="px-6 py-3">
                  1 Year
                </th>
                <th scope="col" class="px-6 py-3">
                  3 Year
                </th>
                <th scope="col" class="px-6 py-3">
                  5 Year
                </th>
              </tr>
            </thead>
            <tbody class="border-b-2 border-dark-blue text-dark-blue">
              <tr v-for="(item, index) in SA_SolutionDataDaily" :key="index" class="bg-white">
                <td class="px-6 text-left">
                  <span class="block mb-6">{{ item.row.Description }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Description }} </span>                 
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.SinceInception.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).SinceInception.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Month1.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Month1.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Month3.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Month3.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Month6.toFixed(2)}}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Month6.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.YearToDate.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).YearToDate.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Year1.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Year1.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Year3.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Year3.toFixed(2) }}</span>
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Year5.toFixed(2) }}</span>
                  <span class="font-light">{{ fetchBench(item.row.Isin).Year5.toFixed(2) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <h2 v-if="!errorMessage" class="font-poppins font-bold text-gold text-2xl mt-12 ml-5">
        Global Solutions (USD)
        <span class="text-dark-blue text-sm font-light ml-6">{{ Global_SolutionDataDaily ? `${day_USD} ${month_USD} ${year_USD}` : 'No data' }}</span>
      </h2>

      <div class="w-1/2 content-table content-container">
        <div class="table-container">
          <table v-if="!errorMessage" class="w-full font-poppins font-semibold text-xs text-center ml-5 mt-6">
            <thead class="text-xs uppercase bg-dark-blue text-white">
              <tr>
                <th scope="col" class="px-6 py-3">
                  
                </th>
                <th scope="col" class="px-6 py-3">
                  Since Inception
                </th>
                <th scope="col" class="px-6 py-3">
                  Monthly
                </th>
                <th scope="col" class="px-6 py-3">
                  3 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  6 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  YTD
                </th>
                <th scope="col" class="px-6 py-3">
                  1 Year
                </th>
                <th scope="col" class="px-6 py-3">
                  3 Year
                </th>
                <th scope="col" class="px-6 py-3">
                  5 Year
                </th>
              </tr>
            </thead>
            <tbody class="border-b-2 border-dark-blue text-dark-blue">
              <tr v-for="(item, index) in Global_SolutionDataDaily" :key="index" class="bg-white">
                <td class="px-6 text-left">
                  <span class="block mb-6">{{ item.row.Description}}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Description }}</span>
                </td>
                <td class="px-6 py-2">
                  <span class="block mb-6">{{ item.row.SinceInception.toFixed(2) }}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).SinceInception.toFixed(2) }}</span>  
                </td>
                <td class="px-6 py-2">
                  <span class="block mb-6">{{ item.row.Month1.toFixed(2) }}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Month1.toFixed(2) }}</span>  
                </td>
                <td class="px-6 py-2">
                  <span class="block mb-6">{{ item.row.Month3.toFixed(2) }} </span>
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Month3.toFixed(2) }} </span>  
                </td>
                <td class="px-6 py-2">
                  <span class="block mb-6">{{ item.row.Month6.toFixed(2) }} </span>
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Month6.toFixed(2) }}</span>  
                </td>
                <td class="px-6 py-2">
                  <span class="block mb-6">{{ item.row.YearToDate.toFixed(2) }}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).YearToDate.toFixed(2) }}</span> 
                </td>
                <td class="px-6 py-2">
                  <span class="block mb-6">{{ item.row.Year1.toFixed(2) }}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Year1.toFixed(2) }}</span>  
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Year3.toFixed(2) }}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Year3.toFixed(2) }}</span>  
                </td>
                <td class="px-6 py-3">
                  <span class="block mb-6">{{ item.row.Year5.toFixed(2) }}</span> 
                  <span class="font-light">{{ fetchBenchGlobal(item.row.Isin).Year5.toFixed(2) }}</span>  
                </td>
              </tr>
            </tbody>
          </table>
        
        </div>
      </div>
    </div> 
   </div> 
   <div>
    <div class="font-poppins text-white flex flex-row justify-between mx-4 mb-4 mt-10">
        <button @click="onClickNavigatePrev()" class="bg-gradient-to-r from-dark-blue-gradient via-dark-blue-gradient to-light-blue-gradient px-4 py-2 rounded-md left-button">
          <span class="flex items-center">
            <svg class="fill-white mr-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
              Underlying Manager Details & Asset Allocation
          </span>
        </button>

        <button @click="onClickNavigateNext()" class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient px-6 py-2 rounded-md">
          <span class="flex items-center">
            Home
            <svg class="fill-white ml-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </span>
        </button>
      </div>
    <Footer />
  </div>
  </MainLayout>
</template>

<style scoped>
@media screen and (max-width: 2500px) {
    .navbar-responsive {
      display: flex;
      justify-content: center;
    }

    .container-whole {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .hide-image {
      display: none;
    }
    .content-container {
      margin-left: -50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .content-table {
      width: 80%;
    }

    .table-container {
      position: relative;
    }
  }
</style>

<script>
    import NavBar from '../components/Atomic/AQuarks/NavBar.vue'; 
    import Footer from '../components/Atomic/AQuarks/Footer.vue';
    import { useRouter } from 'vue-router';
    import axios from '../axiosConfig';
  import MainLayout from '../components/Layouts/MainLayout.vue';

    export default {
      el: '#app',
      name: 'SolutionPage4',
      components: {
        NavBar,
        Footer,
        MainLayout
      },
      setup() {
        const router = useRouter();
        return { router };
      },
      methods: {
        onClickNavigatePrev() {
        this.$router.push(`/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/3`).then(() => {
          window.scrollTo(0, 0);
        })
        },
        onClickNavigateNext() {
        this.$router.push(`/landing`).then(() => {
          window.scrollTo(0, 0);
        });
        },
        fetchBench(isin) {
          let newISIN = "BM" + isin.slice(2)
          let benchmarks = this.parsedZarDailyData.filter(item => item.row.Isin === newISIN);
          return benchmarks[0].row         
        },
        fetchBenchGlobal(isin) {
          let newISIN = "BM" + isin.slice(2)
          let benchmarks = this.parsedUsdDailyData.filter(item => item.row.Isin === newISIN);
          return benchmarks[0].row
        }
      },


      async created() {
        let ISIN = this.$route.params.ISIN;
        let BenchmarkISIN = "BM" + ISIN.slice(2);
        const dailyZarResponse = await axios.get(`/File?filetype=zarDaily`);
        const dailyUsdResponse = await axios.get(`/File?filetype=usdDaily`);

        if (!dailyZarResponse.data || !dailyUsdResponse.data) {
          this.errorMessage = "No data is available at the moment.";
          return; 
        }

        this.parsedZarDailyData = dailyZarResponse.data;
        this.parsedUsdDailyData = dailyUsdResponse.data;

        let balancedZarDailyData = this.parsedZarDailyData.filter((item) => item.row.Description.includes("STRATEGIQ"));
        let filteredUsdDailyData = this.parsedUsdDailyData.filter((item) => item.row.Description.includes("STRATEGIQ"));

        this.SA_SolutionDataDaily = balancedZarDailyData;
        this.Global_SolutionDataDaily = filteredUsdDailyData;

        const createdAtDate_SA = new Date(this.SA_SolutionDataDaily[0].createdAt);
        const day_SA = createdAtDate_SA.getDate();
        const month_SA = createdAtDate_SA.toLocaleString('default', { month: 'long' });
        const year_SA = createdAtDate_SA.getFullYear();

        const createdAtDate_USD = new Date(this.Global_SolutionDataDaily[0].createdAt);
        const day_USD = createdAtDate_USD.getDate();
        const month_USD = createdAtDate_USD.toLocaleString('default', { month: 'long' });
        const year_USD = createdAtDate_USD.getFullYear();

        this.day_SA = day_SA;
        this.month_SA = month_SA;
        this.year_SA = year_SA;

        this.day_USD = day_USD;
        this.month_USD = month_USD;
        this.year_USD = year_USD;
      },
      data() {
        return {
          errorMessage: "",
          SA_SolutionDataDaily: null,
          Global_SolutionDataDaily: null,
          parsedZarDailyData: null,
          parsedUsdDailyData: null,
        }
      },
    }
</script>