<template>
  <div class="flex flex-col justify-between h-screen">
    <NavBar/>

    <div class="flex flex-col font-poppins">
      <div class="font-extrabold px-12">
        <p class="text-dark-blue text-lg">TRACK YOUR SOLUTIONS IN REAL TIME</p>
      </div>
      
      <div class="flex flex-col py-8 px-12 md:flex-row md:space-y-0 w-full">

        <div class="flex-1">
          <div class="flex flex-col">
            <div class="flex flex-col">
              <p class="text-dark-blue text-md font-base font-poppins pr-6">Use our powerful tracking software to monitor
                your solutions in real-time, including performance, asset allocation, underlying holdings, and more.
                This allows you and your clients to assess our solutions all in one place.</p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="w-full">
            <p class="text-dark-blue font-base text-md font-poppins">We believe in full transparency in everything
              we do. View our solutions in real-time. Click on the solution and learn more or download latest fund fact
              sheets, performance & attribution and more from the drop-downs above.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col-reverse md:flex-row gap-y-4 gap-x-16 mb-12"> 
      <div class="w-full md:w-4/12 bg-dark-blue">
        <div class="p-10 md:text-base md:justify-center 2xl:flex 2xl:flex-row 2xl:items-center 2xl:h-full">
          <div class="flex flex-col space-y-4 font-poppins">
            <div class="text-light-blue">
              <p class="sm:text-sm md:text-xl lg:text-2xl text-3xl">STRATEGIQ SA and Global Risk-Profiled Solutions</p>
              <p class="font-bold pt-4">SA Solutions</p>
            </div>
            <div class="flex flex-col text-white">
              <a href="/IQ2065/SA/1">&bull; STRATEGIQ Income Solution</a>
              <a href="/IQ2066/SA/1">&bull; STRATEGIQ Cautious Solution</a>
              <a href="/IQ2064/SA/1">&bull; STRATEGIQ Balanced Solution</a>
              <a href="/IQ2593/SA/1">&bull; STRATEGIQ Multi-Hedge Solution</a>
              <a href="/IQ2158/SA/1">&bull; STRATEGIQ Global Feeder Solution</a>
              <a href="/IQ2186/SA/1">&bull; STRATEGIQ Growth Solution</a>
              <a href="/IQ2578/SA/1">&bull; STRATEGIQ TFSA Solution</a>
            </div>

            <p class="text-light-blue font-bold">Global Solutions</p>
            <div class="flex flex-col text-white">
              <a href="/IQ2643/US/1">&bull; STRATEGIQ Global Cautious Solution</a>
              <a href="/IQ2595/US/1">&bull; STRATEGIQ Global Balanced Solution</a>
              <a href="/IQ2581/US/1">&bull; STRATEGIQ Global Growth Solution</a>
            </div>

            <p class="text-light-blue font-poppins">Click on solution to view</p>

          </div>
        </div>
      </div>

      <div class="w-full md:w-8/12 flex justify-center">
        <div class="flex-col mr-3 overflow-y-auto" style="max-height: 500px;">
          <div>
            <div id="chart1" v-if="!errorMessage">
              <apexchart type="bar" height="350" width="920" :options="globalStockMarketReturnsChartOptions" :series="globalStockMarketReturnsSeries"></apexchart>
            </div>
          </div>

          <div>
            <div id="chart2" v-if="!errorMessage">
              <apexchart type="bar" height="350" width="920" :options="globalAssetClassReturnsUSDChartOptions" :series="globalAssetClassReturnsUSDSeries"></apexchart>
            </div>
          </div>

          <div>
            <div id="chart3" v-if="!errorMessage">
              <apexchart type="bar" height="350" width="920" :options="assetClassReturnsZARChartOptions" :series="assetClassReturnsZARSeries"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import NavBar from '../components/Atomic/AQuarks/NavBar.vue';
import Footer from '../components/Atomic/AQuarks/Footer.vue'
import axios from '../axiosConfig';

export default {
  name: 'ManagerAdmin',
  components: {
    NavBar,
    Footer,
  },

  methods: {
    computeMinAndSetOptions(data, chartOptions, categoryMapping) {
      let minBarGraph = Math.min.apply(Math, [
        Math.min.apply(Math, data.map(item => item.row.YearToDate)),
        Math.min.apply(Math, data.map(item => item.row.Month3)),
        Math.min.apply(Math, data.map(item => item.row.Year1)),
      ]);
      minBarGraph -= 5;

      return Object.assign({}, chartOptions, {
        yaxis: {
          ...chartOptions.yaxis,
          categories: data.map(item => {
            const isin = item.row.Isin;
            return categoryMapping[isin] || item.row.Description.trim();
          }),
          min: minBarGraph,
        },
      });
    },
  },

  async created() {

    const stockMarketResponse = await axios.get(`/File?filetype=stockMarket`)
    const zarDailyResponse = await axios.get(`/File?filetype=zarDaily`)
    const usdDailyResponse = await axios.get(`/File?filetype=usdDaily`)

    if (!stockMarketResponse.data || !zarDailyResponse.data || !usdDailyResponse.data) {
      this.errorMessage = "No data is available at the moment.";
      return;
    }

    const parsedStockMarketData = stockMarketResponse.data;
    const parsedZarDailyData = zarDailyResponse.data;
    const parsedUsdDailyData = usdDailyResponse.data;

    const stockMarketCategoryMapping = {
      'DJSMR': 'Dow Jones',
      'SPSMR': 'S&P 500',
      'NASDAQSMR': 'Nasdaq',
      'STOXXSMR': 'Eorosfoxx 50',
      'FTSESMR': 'FTSE',
      'NIKKEISMR': 'Nikkei 225',
      'HSISMR': 'Hang Seng',
      'ALLSRSMR': 'All Share Index',
    }

    const usdDailyCategoryMapping = {
      'MSCIWUSD': 'MSCI World NR USD',
      'MSCIWGUSD': ['MSCI World', 'Growth NR USD'],
      'MSCIWQUSD': ['MSCI World', 'Quality NR USD'],
      'MSCIWVUSD': ['MSCI World', 'Value NR USD'],
      'MSCIEMUSD': ['MSCI EM NR', 'USD'],
      'WGBIUSD': 'FTSE W BIG USD',
      'BGAUSD': ['Bloomberg Global', 'Agg Corp USD TR', 'USD'],
      'GSCIUSD': 'S&P GSCI TR USD',
      'ICE3USD': 'SOFR',
    }

    const zarDailyCategoryMapping = {
      'MSCIWZAR': ['MSCI World NR', 'USD'],
      'FTSEALLZAR': ['FTSE/JSE All', 'Share TR ZAR'],
      'FTSERESZAR': ['FTSE/JSE', 'Resources 10', 'TR ZAR'],
      'FTSEINDZAR': ['FTSE/JSE', 'Industrials 25', 'TR ZAR'],
      'FTSEFINZAR': ['FTSE/JSE', 'Financial 15', 'TR ZAR'],
      'FTSEPROPZAR': ['FTSE/JSE', 'All Property', 'TR ZAR'],
      'FTSEGOVZAR': ['FTSE/JSE ALB', 'GOV TR ZAR'],
      'BM2065': ['ASISA' ,'South Africa', 'MA Income'],
      'BM2593': 'STEFI Composite',
      'ZAE000162608': 'USDZAR',
    }

    this.stockMarketIsin = parsedStockMarketData.filter(item => stockMarketCategoryMapping[item.row.Isin]);
    this.usdDailyIsin = parsedUsdDailyData.filter(item => usdDailyCategoryMapping[item.row.Isin]);
    this.zarDailyIsin = parsedZarDailyData.filter(item => zarDailyCategoryMapping[item.row.Isin]);

    function replaceMissingDataWithZero(dataArray) {
      return dataArray.map(item => item || 0);
    }

    function sortDataByCategoryMapping(dataArray, categoryMapping) {
      const categoryOrder = Object.keys(categoryMapping);
      return dataArray.sort((a, b) => {
        let categoryAIndex = categoryOrder.indexOf(a.row.Isin);
        let categoryBIndex = categoryOrder.indexOf(b.row.Isin);
        return categoryAIndex - categoryBIndex;
      });
    }

    this.stockMarketIsin = sortDataByCategoryMapping(this.stockMarketIsin, stockMarketCategoryMapping);
    this.usdDailyIsin = sortDataByCategoryMapping(this.usdDailyIsin, usdDailyCategoryMapping);
    this.zarDailyIsin = sortDataByCategoryMapping(this.zarDailyIsin, zarDailyCategoryMapping);

    // Stock Market
    this.globalStockMarketReturnsSeries = [
      {
        name: 'YTD',
        data: replaceMissingDataWithZero(this.stockMarketIsin.map(item => item.row.YearToDate))
      },
      {
        name: '3 Months',
        data: replaceMissingDataWithZero(this.stockMarketIsin.map(item => item.row.Month3))
      },
      {
        name: '1 Year',
        data: replaceMissingDataWithZero(this.stockMarketIsin.map(item => item.row.Year1))
      }
    ]
    
    // USD Daily
    this.globalAssetClassReturnsUSDSeries = [
      {
        name: 'YTD',
        data: replaceMissingDataWithZero(this.usdDailyIsin.map(item => item.row.YearToDate))
      },
      {
        name: '3 Months',
        data: replaceMissingDataWithZero(this.usdDailyIsin.map(item => item.row.Month3))
      },
      {
         name: '1 Year',
        data: replaceMissingDataWithZero(this.usdDailyIsin.map(item => item.row.Year1))
      },
    ]

    // ZAR Daily
    this.assetClassReturnsZARSeries = [
      {
        name: 'YTD',
        data: replaceMissingDataWithZero(this.zarDailyIsin.map(item => item.row.YearToDate))
      },
      {
        name: '3 Months',
        data: replaceMissingDataWithZero(this.zarDailyIsin.map(item => item.row.Month3))
      },
      {
        name: '1 Year',
        data: replaceMissingDataWithZero(this.zarDailyIsin.map(item => item.row.Year1))
      },
    ]

    // Stock Market Returns
    this.globalStockMarketReturnsChartOptions = this.computeMinAndSetOptions(
      this.stockMarketIsin,
      this.globalStockMarketReturnsChartOptions,
      stockMarketCategoryMapping
    );

    this.globalStockMarketReturnsChartOptions.xaxis.categories = this.stockMarketIsin.map(item => {
      const isin = item.row.Isin;
      return stockMarketCategoryMapping[isin] || item.row.Description.trim();
    });


    // USD Daily Returns
    this.globalAssetClassReturnsUSDChartOptions = this.computeMinAndSetOptions(
      this.usdDailyIsin,
      this.globalAssetClassReturnsUSDChartOptions,
      usdDailyCategoryMapping
    );

    this.globalAssetClassReturnsUSDChartOptions.xaxis.categories = this.usdDailyIsin.map(item => {
      const isin = item.row.Isin;
      return usdDailyCategoryMapping[isin] || item.row.Description.trim();
    });


    // ZAR Daily Returns
    this.assetClassReturnsZARChartOptions = this.computeMinAndSetOptions(
      this.zarDailyIsin,
      this.assetClassReturnsZARChartOptions,
      zarDailyCategoryMapping
    );

    this.assetClassReturnsZARChartOptions.xaxis.categories = this.zarDailyIsin.map(item => {
      const isin = item.row.Isin;
      return zarDailyCategoryMapping[isin] || item.row.Description.trim();
    });
  },
  

  data() {
      return {
        errorMessage: "",
        stockMarketIsin: [],
        usdDailyIsin: [],
        zarDailyIsin: [],
        globalStockMarketReturnsSeries: [],
        globalAssetClassReturnsUSDSeries: [],
        assetClassReturnsZARSeries: [],
 
        // Global Stock Market Returns 
        globalStockMarketReturnsChartOptions: {
          chart: {
            type: 'bar',
            height: 400,
            toolbar: {
              show: false,
            }
          },
          title: {
            text: 'Global Stock Market Returns',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#002147'
            }
          },
          legend: {
            height: 25,
            offsetY: 10,
            show: true,
            onItemClick: {
              toggleDataSeries: false
            },
          },
          colors: ['#A17D24', '#002147', '#3CD7D9'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
              columnWidth: '95%',
              endingShape: 'rounded'
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis:{
              lines: {
                show: false 
              }
            }
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: ['#002147'],
              fontFamily: 'Poppins',       
              fontSize: '10px',          
            },                
            offsetY: -20,
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          yaxis: {
            tickAmount: 7,
            forceNiceScale: true,
            max: 100,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '9px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                colors: '#002147',
              },
            },
            tickAmount: 6,
          },
          fill: {
            opacity: 1,
            colors: ['#A17D24', '#002147', '#3CD7D9']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },


        // Global Asset Class Returns (USD)
        globalAssetClassReturnsUSDChartOptions: {
          chart: {
            type: 'bar',
            height: 400,
            toolbar: {
              show: false,
            }
          },
          title: {
            margin: 40,
            text: 'Global Asset Class Returns (USD)',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#002147',
            }
          },
          legend: {
            height: 25,
            offsetY: 10,
            show: true,
            onItemClick: {
              toggleDataSeries: false
            }
          },
          colors: ['#A17D24', '#002147', '#3CD7D9'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
              columnWidth: '95%',
              endingShape: 'rounded'
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis:{
              lines: {
                show: false 
              }
            }
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: ['#002147'],
              fontFamily: 'Poppins',  
              fontSize: '10px'                
            },                
            offsetY: -20,
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          yaxis: {
            tickAmount: 7,
            forceNiceScale: true,
            max: 100
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: '9px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                colors: '#002147',
              },
            },
            tickAmount: 6,
          },
          fill: {
            opacity: 1,
            colors: ['#A17D24', '#002147', '#3CD7D9']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },


        // SA Asset Class Returns (ZAR)
        assetClassReturnsZARChartOptions: {
          chart: {
            type: 'bar',
            height: 400,
            toolbar: {
              show: false,
            }
          },
          title: {
            margin: 40,
            text: 'SA Asset Class Returns (ZAR)',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#002147',
            }
          },
          legend: {
            height: 25,
            offsetY: 10,
            show: true,
            onItemClick: {
              toggleDataSeries: false
            },
          },
          colors: ['#A17D24', '#002147', '#3CD7D9'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
              columnWidth: '95%',
              endingShape: 'rounded'
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis:{
              lines: {
                show: false 
              }
            }
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: ['#002147'],
              fontFamily: 'Poppins',         
              fontSize: '10px'        
            },                
            offsetY: -20,
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          yaxis: {
            tickAmount: 7,
            forceNiceScale: true,
            max: 100,
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: '9px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                colors: '#002147',
              }
            },
            tickAmount: 6,
          },
          fill: {
            opacity: 1,
            colors: ['#A17D24', '#002147', '#3CD7D9']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },
 
      }
    }
}
</script>


<style scoped>
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #7C7878;
  border-radius: 4px;
}
</style>