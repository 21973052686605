import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import auth from '../auth';
import keycloak from '../keycloak';
import ManagerAdmin from '../views/ManagerAdmin.vue'
import LandingView from '../views/LandingView.vue'
import SolutionPage2 from '../views/SolutionPage2.vue'
import SolutionPage3 from '../views/SolutionPage3.vue'
import SolutionPage1 from '../views/SolutionPage1.vue'
import SolutionPage4 from '../views/SolutionPage4.vue'
import UploadFiles from '../views/UploadFiles.vue'
import ManagerPage from '../views/ManagerPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/landing'
  },
  {
    path: '/admin/manager',
    name: 'Manager Admin',
    component: ManagerAdmin,
    beforeEnter: (to, from, next) => {
      if (keycloak.hasRealmRole('Admin')) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/landing',
    name: 'Landing',
    component: LandingView,
    beforeEnter: (to, from, next) => {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        next();
      }
    },
  },
  {
    path: '/:ISIN/:LOCALE/1',
    name: 'SolutionPage1',
    component: SolutionPage1,
    beforeEnter: (to, from, next) => {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        next();
      }
    }
  },
  {
    path: '/:ISIN/:LOCALE/2',
    name: 'SolutionPage2',
    component: SolutionPage2,
    beforeEnter: (to, from, next) => {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        next();
      }
    }
  },
  {
    path: '/:ISIN/:LOCALE/3',
    name: 'SolutionPage3',
    component: SolutionPage3,
    beforeEnter: (to, from, next) => {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        next();
      }
    }
  },
  {
    path: '/:ISIN/:LOCALE/4',
    name: 'SolutionPage4',
    component: SolutionPage4,
    beforeEnter: (to, from, next) => {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        next();
      }
    }
  },
  {
    path: '/upload',
    name: 'UploadFiles',
    component: UploadFiles,
    beforeEnter: (to, from, next) => {
      if (keycloak.hasRealmRole('Admin')) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/:ISIN/:FROMISIN/:LOCALE/details',
    name: 'Manager Page',
    component: ManagerPage,
    beforeEnter: (to, from, next) => {
      if (!keycloak.authenticated) {
        next('/login');
      } else {
        next();
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export function setupAuthGuard() {
  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const { user, role } = await auth.getUser();

    if (to.query.code) {
      await auth.handleCallback();
      next('/');
      return;
    }

    if (requiresAuth && !user) {
      auth.signIn();
    } else {
      const requiredRoles = to.meta.requiredRoles as Array<string>;
      if (requiredRoles && !requiredRoles.includes(role)) {
        next('/');
      } else {
        next();
      }
    }
  });
}

export default router