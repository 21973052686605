import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: 'https://identity.strategiqinvest.com/',
  realm: 'strategiq-prod',
  clientId: 'strategiq-login',
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;