<template>
<MainLayout>
<NavBar/>
<!-- Get this heading from data based on ISIN code -->
<div class="flex flex-col">
<div class="flex flex-row">
<div class="space-y-4">
  <h1 class="font-poppins text-3xl text-dark-blue font-bold mt-6 ml-5 md:mt-6 lg:mt-10">{{ solutionDataMonthly ? solutionDataMonthly[0].row.Description : 'No data' }}</h1>
  <p class="text-gold ml-5 text-lg font-bold font-poppins">Sector & Geographical Exposure</p>
</div>
</div>

<div>
  <h3 v-if="errorMessage" class="font-poppins text-center font-extrabold text-gold mt-14">No data is available at the moment.</h3>
</div>

<div class="w-full flex flex-row justify-center">
    <div class="w-1/3">
      <div v-if="!errorMessage" class="chart" style="flex: 1;">
          <apexchart type="heatmap" :options="chartOptions" :series="heatMapSeries"></apexchart>
      </div>
    </div>
    <div class="w-1/2 flex flex-col justify-center space-y-10">
      <div class="text-dark-blue font-poppins">
        <table v-if="!errorMessage" class="table-auto w-1/3 mx-auto my-auto" id="table">
          <thead>
            <tr>
              <th class="border-b-2 border-dark-blue text-left">Equity Style</th>
              <th class="border-b-2 border-dark-blue text-center">Perc %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pt-2">Equity Style Value</td>
              <td class="pt-2 text-center">{{ solutionDataMonthly ? solutionDataMonthly[0].row.EquityStyleValue : 'No data' }}</td>
            </tr>
            <tr>
              <td>Equity Style Core</td>
              <td class="text-center">{{ solutionDataMonthly ? solutionDataMonthly[0].row.EquityStyleCore : 'No data' }}</td>
            </tr>
            <tr class="border-b-2 border-dark-blue">
              <td class="pb-2">Equity Style Growth</td>
              <td class="pb-2 text-center">{{ solutionDataMonthly ? solutionDataMonthly[0].row.EquityStyleGrowth : 'No data' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table v-if="!errorMessage" class="table-auto w-1/3 text-dark-blue font-poppins mx-auto my-auto" id="table">
          <thead>
            <tr>
              <th class="border-b-2 border-dark-blue text-left">Region Exposure</th>
              <th class="border-b-2 border-dark-blue text-center">Perc %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pt-2">Developed Markets</td>
              <td class="pt-2 text-center">{{ solutionDataMonthly ? solutionDataMonthly[0].row.EquityRegionDeveloped : 'No data' }}</td>
            </tr>
            <tr class="border-b-2 border-dark-blue">
              <td class="pb-2">Emerging Markets</td>
              <td class="pb-2 text-center">{{ solutionDataMonthly ? solutionDataMonthly[0].row.EquityRegionEmerging : 'No data' }}</td>
            </tr>          
          </tbody>
        </table>
      </div>
    </div>
</div>
<div class="flex flex-row justify-around">
  <div v-if="!errorMessage" class="flex flex-col w-[30%]">
    <p class="font-bold text-dark-blue font-poppins text-lg mt-4 mx-auto">Sector Exposure</p>
    <div id="sectorExposureChart" class="">
      <apexchart type="bar" height="350" :options="sectorExposureChartOptions" :series="sectorExposureChartSeries"></apexchart>
    </div>
  </div>
  <div v-if="!errorMessage" class="flex flex-col w-[30%]">
    <p class="font-bold text-dark-blue font-poppins text-lg mt-4 mx-auto">Country Exposure</p>
    <div id="countryExposureChart">
      <apexchart type="bar" height="350" :options="countryExposureChartOptions" :series="countryExposureChartSeries"></apexchart>
    </div>
  </div>
  <div v-if="!errorMessage" class="flex flex-col w-[30%]">
    <p class="font-bold text-dark-blue font-poppins text-lg mt-4 mx-auto">Top 10 Company Holdings</p>
    <div id="top10CompanyHoldingsChart">
      <apexchart type="bar" height="350" :options="top10CompanyHoldingsChartOptions" :series="top10CompanyHoldingsChartSeries"></apexchart>
    </div>
  </div>
 </div>
</div>
<div>
  <div class="flex flex-row justify-between mx-4 my-4">
    <button @click="onClickNavigatePrev()" class="bg-gradient-to-r from-dark-blue-gradient via-dark-blue-gradient to-light-blue-gradient text-white p-2 font-poppins rounded-md"><span class="flex items-center">
    <svg class="fill-white mr-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"> <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
              Investment Objective & Strategy
          </span>
    </button>
    <button @click="onClickNavigateNext()" class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient text-white p-2 rounded-md font-poppins">
    <span class="flex items-center">
            Underlying Manager Details & Asset Allocation
            <svg class="fill-white ml-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"> <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </span>
    </button>
  </div>
  <Footer/>
</div>
</MainLayout>
</template>

<script>
import NavBar from '../components/Atomic/AQuarks/NavBar.vue';
import Footer from '../components/Atomic/AQuarks/Footer.vue';
import axios from '../axiosConfig';
import { useRouter } from 'vue-router';
import MainLayout from '../components/Layouts/MainLayout.vue';

export default {
    components: {
        NavBar,
        Footer,
        MainLayout,
    },
    setup() {
      const router = useRouter();

      return {router};
    },
    async created() {
      let locale = ''
      if(this.$route.params.LOCALE == 'SA')
      {
        locale = "zar"
      }
      else{
        locale = "usd"
      }
      let ISIN = this.$route.params.ISIN;
      let BenchmarkISIN = "BM" + ISIN.slice(2);
      const monthlyResponse = await axios.get(`/File?filetype=${locale}Monthly`); 

      if (!monthlyResponse.data) {
        this.errorMessage = "No data is available at the moment.";
        return;
      }

      const parsedMonthlyData = monthlyResponse.data;
      let filteredISINMonthlyData = parsedMonthlyData.filter(item => item.row.Isin === ISIN)
      this.solutionDataMonthly = filteredISINMonthlyData;

      this.heatMapSeries = [
        { name: 'Small', data: [this.solutionDataMonthly[0].row.ValueSmall, this.solutionDataMonthly[0].row.BlendSmall, this.solutionDataMonthly[0].row.GrowthSmall] },
        { name: 'Mid', data: [this.solutionDataMonthly[0].row.ValueMid, this.solutionDataMonthly[0].row.BlendMid, this.solutionDataMonthly[0].row.GrowthMid] },
        { name: 'Large', data: [this.solutionDataMonthly[0].row.ValueLarge, this.solutionDataMonthly[0].row.BlendLarge, this.solutionDataMonthly[0].row.GrowthLarge] }
      ];

      this.sectorExposureChartSeries = [ 
        {
          data: [this.solutionDataMonthly[0].row.RealEstateSector, 
                this.solutionDataMonthly[0].row.UtilitiesSector, 
                this.solutionDataMonthly[0].row.CommunicationServicesSector, 
                this.solutionDataMonthly[0].row.InformationTechnologySector, 
                this.solutionDataMonthly[0].row.FinancialsSector, 
                this.solutionDataMonthly[0].row.HealthcareSector, 
                this.solutionDataMonthly[0].row.ConsumerStaples, 
                this.solutionDataMonthly[0].row.ConsumerDiscretionary, 
                this.solutionDataMonthly[0].row.IndustrialsSector, 
                this.solutionDataMonthly[0].row.MaterialsSector, 
                this.solutionDataMonthly[0].row.EnergySector
              ]
        }
      ];

      this.countryExposureChartSeries = [
        {
          data: [this.solutionDataMonthly[0].row.NorthAmericaRegion, 
                this.solutionDataMonthly[0].row.LatinAmericaRegion, 
                this.solutionDataMonthly[0].row.UnitedKingdomRegion, 
                this.solutionDataMonthly[0].row.EuropeDevelopedRegion, 
                this.solutionDataMonthly[0].row.EuropeEmergingRegion,          
                this.solutionDataMonthly[0].row.AfricaMiddleEastRegion,
                this.solutionDataMonthly[0].row.AustralasiaRegion,
                this.solutionDataMonthly[0].row.JapanRegion,
                this.solutionDataMonthly[0].row.AsiaDevelopedRegion,
                this.solutionDataMonthly[0].row.AsiaEmergingRegion,
              ]
        }
      ];

      let companies = this.solutionDataMonthly[0].row.Companies;
      let companyData = Object.keys(companies).map(companyName => ({ name: companyName, value: companies[companyName] }));
      companyData.sort((a, b) => b.value - a.value);
      let top10Companies = companyData.slice(0, 10);

      this.top10CompanyHoldingsChartOptions = Object.assign({}, this.top10CompanyHoldingsChartOptions, {
        xaxis: {
          ...this.top10CompanyHoldingsChartOptions.xaxis,
          categories: top10Companies.map(company => company.name)
        }
      });


      this.top10CompanyHoldingsChartSeries = [
        {
          data: top10Companies.map(company => company.value.toFixed(1)),
        }
      ];

    },
    data() {
        return {
          errorMessage: "",
          heatMapSeries: [],
          sectorExposureChartSeries: [],
          countryExposureChartSeries: [],
          top10CompanyHoldingsChartSeries: [],
          chartOptions: {
            chart: {
              type: 'heatmap',
              toolbar: {
                show: false,
              }
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#002147'],
                fontFamily: 'Poppins'
              }
            },
            legend: {
              show: false,
            },
            plotOptions: {
              heatmap: {                
                enableShades: true,
                colorScale: {
                  ranges: [
                    { from: 0, to: 20, color: '#5A8AC6' }, // darker blue
                    { from: 20, to: 40, color: '#7DA6D2' }, // lighter blue
                    { from: 40, to: 60, color: '#FFFFFF' }, // white
                    { from: 60, to: 80, color: '#FDACA9' }, // lighter red
                    { from: 80, to: 100, color: '#F8696B' }, // darker red
                  ],
                },
              }
            },
            xaxis: {
              categories: ['Value', 'Blend', 'Growth'],
              labels: {
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',
                  fontWeight: 'Bold'
                }
              },
              position: 'top',
              
            },
            yaxis: {
              categories: ['Small', 'Mid', 'Large'],
              labels: {
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',
                  fontWeight: 'Bold'
                }
              }
            },
          },
            sectorExposureChartOptions: {
              chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                  show: false,
                }
              },
              tooltip: {
                y: {
                  formatter: function (value, { dataPointIndex, w }) {
                    const category = w.config.xaxis.categories[dataPointIndex];
                    return `${value}%`;
                  },
                  title: {
                  formatter: function (value, { dataPointIndex, w }) {
                    const category = w.config.xaxis.categories[dataPointIndex];
                    return `${category}`
                  }
                }
                },
              },
              colors: ['#002147'],
              plotOptions: {
                bar: {                  
                  horizontal: true,                  
                  dataLabels: {
                    position: 'top'
                  },
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',                  
                },
                formatter: function (val) {
                  return val + "%"
                },
                offsetX: 30,

              },
              xaxis: {
                categories: ['Real Estate', 'Utilities', 'Communication Services', 'Information Technology', 'Financials', 'Healthcare', 'Consumer Staples',
                  'Consumer Discretionary', 'Industrials', 'Materials', 'Energy'
                ],
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
              },
              yaxis: {
                labels: {
                  style: {
                    colors: ['#002147'],
                    fontFamily: 'Poppins',
                  }
                },
                axisBorder: {
                show: false,
                },
            },
                grid: {
                    show: false
                }
            },
            countryExposureChartOptions: {
              chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                  show: false,
                }
              },
              tooltip: {
                y: {
                  formatter: function (value, { dataPointIndex, w }) {
                    const category = w.config.xaxis.categories[dataPointIndex];
                    return `${value}%`;
                  },
                  title: {
                  formatter: function (value, { dataPointIndex, w }) {
                    const category = w.config.xaxis.categories[dataPointIndex];
                    return `${category}`
                  }
                }
                },
              },
              colors: ['#3CD7D9'],
              plotOptions: {
                bar: {                  
                  horizontal: true,                  
                  dataLabels: {
                    position: 'top'
                  },
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',                  
                },
                formatter: function (val) {
                  return val + "%"
                },
                offsetX: 35,

              },
              xaxis: {
                categories: ['North America', 'Latin America', 'United Kingdom', 'Europe dev', 'Europe emrg', 'Africa/Middle East', 'Australasia', 'Japan', 'Asia dev', 'Asia emrg'
                ],
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
              },
              yaxis: {
                labels: {
                  style: {
                    colors: ['#002147'],
                    fontFamily: 'Poppins',
                  }
                },
                axisBorder: {
                show: false,
                },
            },
                grid: {
                    show: false
                }
            },
            top10CompanyHoldingsChartOptions: {
              chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                  show: false,
                }
              },
              tooltip: {
                y: {
                  formatter: function (value, { dataPointIndex, w }) {
                    const category = w.config.xaxis.categories[dataPointIndex];
                    return `${value}%`;
                  },
                  title: {
                  formatter: function (value, { dataPointIndex, w }) {
                    const category = w.config.xaxis.categories[dataPointIndex];
                    return `${category}`
                  }
                }
                },
              },
              colors: ['#A17D24'],
              plotOptions: {
                bar: {                  
                  horizontal: true,                  
                  dataLabels: {
                    position: 'top'
                  },
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',                  
                },
                formatter: function (val) {
                  return val + "%"
                },
                offsetX: 35,

              },
              xaxis: {
                categories: [],
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
              },
              yaxis: {
                labels: {
                  style: {
                    colors: ['#002147'],
                    fontFamily: 'Poppins',
                  }
                },
                axisBorder: {
                show: false,
                },
            },
                grid: {
                    show: false
                }            
          }
        }
    },
    methods: {      
      onClickNavigatePrev() {
        this.$router.push(`/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/1`).then(() => {
          window.scrollTo(0, 0);
        });
      },
      onClickNavigateNext() {
        this.$router.push(`/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/3`).then(() => {
          window.scrollTo(0, 0);
        });
      }
    },
    
}
</script>
