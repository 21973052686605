<template>
  <nav class="bg-dark-blue h-28 top-0 fixed flex items-center px-20 w-full z-50 justify-around">
    <img src="../../../../public/img/strategiq_white_logo.svg" alt="logo" class="h-16 w-auto mr-8" />
    <p class="text-3xl font-poppins font-bold mr-[70px] text-white">Manager Admin Page</p>
    <button @click="redirectHome()" class="text-white bg-darkblue border-l border-r border-white h-28 px-16  py-1 font-poppins uppercase">
    <a class="text-sm font-semibold">Home</a>
    </button>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  components: {
  },
  setup() {
    const router = useRouter();
    return { router };
  },

  methods: {
    redirectHome() {
      this.$router.push("/landing").then(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>