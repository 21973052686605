<template>
  <MainLayout>
  <NavBar/>
  <!-- Get this heading from data based on ISIN code -->
  <div class="flex flex-col">
  <div class="space-y-4">
    <h1 class="font-poppins text-3xl text-dark-blue font-bold mt-6 ml-5 md:mt-6 lg:mt-10">{{ solutionDataMonthly ? solutionDataMonthly[0].row.Description : 'No data' }}</h1>
    <p class="text-gold ml-5 text-lg font-bold font-poppins">Underlying Manager Details & Asset Allocation</p>
  </div>

  <div>
    <h3 v-if="errorMessage" class="font-poppins text-center font-extrabold text-gold mt-14">No data is available at the moment.</h3>
  </div>

  <div class="flex flex-row justify-around space-y-6">
    <div class="w-1/2 flex flex-col items-center mt-2">
      <div v-if="!errorMessage" class="w-full px-4">
          <div class="flex flex-row items-center mt-4 mb-2">
          <p class="font-bold text-dark-blue font-poppins text-lg">Click on manager to download the latest manager view</p>
          <svg class="fill-dark-blue ml-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"> <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
          <table class="table-auto text-xs font-poppins w-full">
          <thead class="bg-dark-blue text-xs text-white">
              <tr>
              <th class="px-4 py-4"></th>
              <th class="px-2 py-4 font-normal">Active or Passive</th>
              <th class="px-4 py-4 font-normal">Classification</th>
              <th class="px-4 py-4 font-normal">Total Expense Ratio</th>            
              </tr>
          </thead>        
          <tbody class="text-dark-blue font-normal border-b text-center border-dark-blue">
            <tr v-for="(item, index) in terMonthlyData" :key="index">
                <template v-if="!item.row.Description.includes('STRATEGIQ')">
                    <td class="px-6 py-2">
                        <a :href="`/${ item.row.Isin }/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/details`" class="text-left">{{ item.row.Description }}</a>
                    </td>
                    <td class="px-2 py-2">
                        <span>{{ item.row.StrategyType }}</span>
                    </td>
                    <td class="px-4 py-2">
                        <span>{{ item.row.Classification }}</span>
                    </td>
                    <td class="px-2 py-2">
                        <span>{{ item.row.TotalExpenseRatio }}</span>
                    </td>
                </template>         
            </tr>
            <tr v-for="(item, index) in terMonthlyData" :key="index">
                <template v-if="item.row.Description.includes('STRATEGIQ')">
                    <td class="px-6 py-2">
                        <span class="font-bold text-left">{{ item.row.Description }}</span>
                    </td>
                    <td class="px-2 py-2">
                        <span></span>
                    </td>
                    <td class="px-4 py-2">
                        <span></span>
                    </td>
                    <td class="px-2 py-2">
                        <span class="font-bold">{{ item.row.TotalExpenseRatio }}</span>
                    </td>
                </template>         
            </tr>
          </tbody>
          </table>
        </div>
        <div v-if="!errorMessage" class="flex flex-col w-full">
            <p class="font-bold text-dark-blue font-poppins text-lg mt-4 pt-6 pb-4 mx-auto">Performance Attribution</p>
            <div id="sectorExposureChart" class="mx-4">
                <apexchart type="bar" height="350" :options="sectorExposureChartOptions" :series="sectorExposureChartSeries"></apexchart>
            </div>
        </div>
    </div>
  
    <div class="flex flex-col justify-around px-4">
      <div v-if="!errorMessage" class="w-[600px] my-auto">
          <p class="font-bold text-dark-blue font-poppins text-lg mt-4 py-4 mx-auto">Active Passive Holdings</p>
          <div id="activePassiveChart">
              <apexchart type="donut" :options="activePassiveChartOptions" :series="activePassiveChartSeries"></apexchart>
          </div>
      </div>
      <div v-if="!errorMessage" class="w-[600px] my-auto">
          <p class="font-bold text-dark-blue font-poppins text-lg mt-4 py-4 mx-auto">Asset Allocation</p>
          <div id="chart">
              <apexchart type="donut" :options="donutChartOptions" :series="donutChartSeries"></apexchart>
          </div>
      </div>
    </div>
   </div>
  </div>
  <div>
    <div class="flex flex-row justify-between mx-4 my-4">
      <button @click="onClickNavigatePrev()" class="bg-gradient-to-r from-dark-blue-gradient via-dark-blue-gradient to-light-blue-gradient text-white p-2 font-poppins rounded-md">
            <span class="flex items-center">
              <svg class="fill-white mr-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"> <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                Sector & Geographical Exposure
            </span>
        </button>
      <button @click="onClickNavigateNext()" class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient text-white p-2 rounded-md font-poppins">
            <span class="flex items-center">
              Performance Summary
              <svg class="fill-white ml-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"> <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
            </span>
        </button>
    </div>
    <Footer/>
  </div>
  </MainLayout>
  </template>

  <style scoped>
    .page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  </style>
  
  <script>
  import NavBar from '../components/Atomic/AQuarks/NavBar.vue';
  import Footer from '../components/Atomic/AQuarks/Footer.vue';
  import axios from '../axiosConfig';
  import { useRouter } from 'vue-router';
  import MainLayout from '../components/Layouts/MainLayout.vue';
  
  export default {
      components: {
          NavBar,
          Footer,
          MainLayout
      },
      setup() {
        const router = useRouter();
  
        return {router};
      },
      async created() {
        let locale = ''
        if(this.$route.params.LOCALE == 'SA')
        {
          locale = "zar"
        }
        else{
          locale = "usd"
        }
        let ISIN = this.$route.params.ISIN;
        const terResponse = await axios.get(`/File?filetype=terMonthly`);
        const dailyResponse = await axios.get(`/File?filetype=${locale}Daily`);
        const monthlyResponse = await axios.get(`/File?filetype=${locale}Monthly`);
        const managerResponse = await axios.get(`/File?filetype=managers`);

        if (!dailyResponse.data || !monthlyResponse.data || !managerResponse.data || !terResponse.data) {
          this.errorMessage = "No data is available at the moment.";
          return; 
        }


        // FILTER BY SOLUTION
        // The user selects the solution before getting this page, so we remove all values that is not related to that solution. So for example we do not want x here when the user selected y
        // ==================

        // Daily
        const parsedDailyData = dailyResponse.data;
        let filteredISINDailyData = parsedDailyData
        this.solutionDataDaily = filteredISINDailyData;
  
        // Monthly
        const parsedMonthlyData = monthlyResponse.data;
        let filteredISINMonthlyData = parsedMonthlyData.filter(item => item.row.Isin === ISIN)
        this.solutionDataMonthly = filteredISINMonthlyData;
  
        // Ter Monthly
        const parsedTerMonthlyData = terResponse.data;
        let filteredTerMonthlyData = parsedTerMonthlyData
          .filter(item => item.row.Solution.trim() === this.solutionDataMonthly[0].row.Description.trim())
  
        // Performance
        let filteredPerformanceData = parsedTerMonthlyData
          .filter(item => item.row.Solution.trim() === this.solutionDataMonthly[0].row.Description.trim())

        // Managers
        const parsedManagerData = managerResponse.data;
        let filteredManagerData = parsedManagerData
  
        this.solutionPerformanceData = filteredPerformanceData;
        this.terMonthlyData = this.mergeData(filteredTerMonthlyData, filteredManagerData);

        this.activePassiveChartSeries = [
          this.solutionDataMonthly[0].row.Active, this.solutionDataMonthly[0].row.Passive
        ];

        let matchingDailyData = this.solutionDataDaily.filter(dailyItem => {
          let match = this.solutionPerformanceData.find(performanceItem => performanceItem.row.Isin === dailyItem.row.Isin);

          return !!match;
      });

      
        matchingDailyData.sort((a, b) => a.row.Description.trim().localeCompare(b.row.Description.trim()));
        this.solutionPerformanceData.sort((a, b) => a.row.Description.trim().localeCompare(b.row.Description.trim()));

        let minMax = Math.max.apply(Math, [Math.abs(Math.min.apply(Math, matchingDailyData.map(item => item.row.Month3))), Math.abs(Math.max.apply(Math, matchingDailyData.map(item => item.row.Month3)))])
        minMax = minMax + 5

        this.sectorExposureChartOptions = Object.assign({}, this.sectorExposureChartOptions, {
          xaxis: {
            ...this.sectorExposureChartOptions.xaxis,
            categories: matchingDailyData.map(item => item.row.Description.trim()),
            min: -minMax,
            max: minMax
          }
        });
        
        this.sectorExposureChartSeries = [
          {
            name: 'Return: 3 Months',
            data: matchingDailyData.map(row => row.row.Month3)
          },
          {
            name: 'Return: 1 Month',
            data: matchingDailyData.map(row => row.row.Month1)
          }
        ];
        
        this.donutChartSeries = [].concat(...this.solutionDataMonthly.map(item => Object.values(item.row.Allocations)));
  
        this.donutChartOptions = Object.assign({}, this.donutChartOptions, {
            labels: Object.keys(this.solutionDataMonthly[0].row.Allocations)
        });
  
      },
      data() {
          return {
              activeRowsCount: null,
              errorMessage: "",
              passiveRowsCount: null,
              managers: [],
              donutChartSeries: [],
              sectorExposureChartSeries: [],
              activePassiveChartSeries: [],
              donutChartOptions: {
                  chart: {
                type: 'donut',
              },
              colors: ["#002147", "#46D7D8", "#C0DEDE", "#DFD1CB", "#A17D24", "#A6A6A6", "#C3AF9F", "#1F4F9A"],
              labels: [],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  }
                }
              }],
              legend: {
                  position: 'left',
                  fontFamily: 'Poppins',
                  width: 200,
                  markers: {
                      width: 12,
                      height: 12,
                      strokeWidth: 0,
                      strokeColor: '#fff',
                      radius: 12,                    
                      offsetX: 0,
                      offsetY: 0
                  },
                  itemMargin: {
                      horizontal: 5,
                      vertical: 0
                  }
              },
              plotOptions: {
                  pie: {
                      donut: {
                        size: '65%',
                          labels: {
                              show: false                                                  
                          }
                      }
                  }            
              },
              dataLabels: {
                  style: {
                    fontFamily: 'Poppins',
                    cssClass: 'font-normal'
                  }
                },
              },
              activePassiveChartOptions: {
                chart: {
                type: 'donut',
                height: '80%'
              },
              dataLabels: {
                enabled: true,
                style : {
                  fontFamily: 'Poppins'
                }
              },
              colors: ["#002147", "#46D7D8"],
              labels: ["Active", "Passive"],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },                
                }
              }],
              legend: {
                  position: 'left',
                  fontFamily: 'Poppins',
                  width: 200,
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: '65%'
                  }
                }
              }            
              },
              sectorExposureChartOptions: {
                chart: {
                  type: 'bar',
                  height: 500,
                  toolbar: {
                    show: false,
                  }
                },              
                plotOptions: {
                  bar: {
                    dataLabels: {
                      position: 'top'
                    },
                    horizontal: true
                  }
                },
                colors: ['#002147', '#3CD7D9'],
                dataLabels: {
                  enabled: true,
                  style: {
                    colors: ['#002147'],
                    fontFamily: 'Poppins',
                    fontSize: '10px',

                  },
                  formatter: function (val) {
                    return val + "%"
                  },
                  offsetX: 40,
  
                },
                stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
                xaxis: {
                  categories: [],
                  labels: {
                      show: false,
                                        
                  },
                  axisBorder: {
                      show: false,
                  },
                },
                yaxis: {
                  labels: {
                      show: true,
                      style: {
                        fontFamily: "Poppins",
                        cssClass: 'font-bold',
                        fontSize: '12px',
                      },
                      maxWidth: 250,  // Increase this value
                  },
                  axisBorder: {
                    show: false,
                  },
              },
                  grid: {
                      show: false
                  },
                  legend: {
                    show: true,
                    labels: {
                      colors: ['#002147'],
                      fontFamily: 'Poppins',
                    },
                    markers: {
                    fillColors: ['#002147', '#3CD7D9'], // Specify the desired fill colors for the legend markers
                  },
                },
              },            
          }
      },
      methods: {
        onClickNavigatePrev() {
          this.$router.push(`/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/2`).then(() => {
            window.scrollTo(0, 0);
          });
        },
        onClickNavigateNext() {
          this.$router.push(`/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/4`).then(() => {
            window.scrollTo(0, 0);
          });
        },
        mergeData(terData, managersData) {
          return terData.map(terItem => {
            const managerItem = managersData.find(
              managerItem => managerItem.row.Description === terItem.row.Description
            );
  
            if (managerItem) {
              return {
                ...terItem,
                row: {
                  ...terItem.row,
                  StrategyType: terItem.row.StrategyType,
                  Classification: terItem.row.Classification,
                },
              };
            }
            return terItem;
          });
          
        },
      },
      
  }
  </script>