<template>
  <nav class="bg-white flex items-center justify-center">
    <a href="#" @click="homeClick()">
      <img src="../../../../public/img/logo_navbar.svg" alt="logo" class="h-10 w-auto mr-8" />
    </a>
    <div class="flex items-between">
      <div class="flex">
        <button id="navbarButton" class="inline-flex items-center text-gold space-x-1 h-28 px-2 py-1" @mouseover="hoverDropdown = 'monthlyFactSheets'" @mouseleave="hoverDropdown = null">
          <span class="text-sm font-semibold font-poppins uppercase hover:text-dark-blue">Monthly fact sheets</span>          
          <svg id="navbarSVG" class="h-5 text-gold hover:text-dark-blue transition-all duration-200" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" fill="currentColor" width="48">
            <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"/>
          </svg>
          <div v-if="hoverDropdown === 'monthlyFactSheets'" class="font-poppins text-left absolute bg-white z-40 py-2 px-4 w-50 shadow-lg top-20 max-h-80 overflow-y-auto">
            <a @click="fetchPDFLink('IQ2065')" class="block py-2 hover:text-dark-blue">Income Solution</a>
            <a @click="fetchPDFLink('IQ2066')" href="#" class="block py-2 hover:text-dark-blue">Cautious Solution</a>
            <a @click="fetchPDFLink('IQ2064')" href="#" class="block py-2 hover:text-dark-blue">Balanced Solution</a>
            <a @click="fetchPDFLink('IQ2593')" href="#" class="block py-2 hover:text-dark-blue">Multi-Hedge Solution</a>
            <a @click="fetchPDFLink('IQ2158')" href="#" class="block py-2 hover:text-dark-blue">Global Feeder Solution</a>
            <a @click="fetchPDFLink('IQ2186')" href="#" class="block py-2 hover:text-dark-blue">Growth Solution</a>
            <a @click="fetchPDFLink('IQ2578')" href="#" class="block py-2 hover:text-dark-blue">TFSA Solution</a>
            <a @click="fetchPDFLink('IQ2643')" href="#" class="block py-2 hover:text-dark-blue">Global Cautious Solution</a>
            <a @click="fetchPDFLink('IQ2595')" href="#" class="block py-2 hover:text-dark-blue">Global Balanced Solution</a>
            <a @click="fetchPDFLink('IQ2581')" href="#" class="block py-2 hover:text-dark-blue">Global Growth Solution</a>
          </div>
        </button>

        <button id="navbarButton" class="inline-flex items-center text-gold space-x-1 h-28 px-2 py-1" @mouseover="hoverDropdown = 'performanceAttribution'" @mouseleave="hoverDropdown = null">
          <span class="text-sm font-semibold font-poppins uppercase hover:text-dark-blue">Performance & Attribution</span>          
          <svg id="navbarSVG" class="h-5 text-gold hover:text-dark-blue transition-all duration-200" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" fill="currentColor" width="48">
              <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"/>
          </svg>
          <div v-if="hoverDropdown === 'performanceAttribution'" class="font-poppins text-left absolute bg-white py-2 px-4 w-50 shadow-lg top-20 max-h-80 overflow-y-auto">
            <a @click="fetchPDFLink('ZASOL')" href="#" class="block py-2 hover:text-dark-blue">SA Solutions (ZAR)</a>
            <a @click="fetchPDFLink('USSOL')" href="#" class="block py-2 hover:text-dark-blue">Global Solutions (USD)</a>
          </div>
        </button>

        <a href="#" @mouseover="hoverDropdown = 'managerResearch'" @mouseleave="hoverDropdown = null">
          <button id="navbarButton" class="inline-flex items-center text-gold space-x-1 h-28 px-2 py-1">
              <span class="text-sm font-semibold font-poppins uppercase hover:text-dark-blue">Manager Research</span>          
              <svg id="navbarSVG" class="h-5 text-gold hover:text-dark-blue transition-all duration-200" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" fill="currentColor" width="48">
                  <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"/>
              </svg>
              <div v-if="hoverDropdown === 'managerResearch' && managers.length > 0" class="font-poppins text-left absolute bg-white py-2 z-40 w-50 shadow-lg top-20 max-h-80 overflow-y-auto">
                  <div v-for="manager in managers">
                      <a @click="fetchManagerPDFLink(manager.row.Isin)" href="#" class="block px-4 py-2 hover:text-dark-blue">{{ manager.row.Description }}</a>
                  </div>
              </div>
          </button>
        </a>

        <a @mouseover="hoverDropdown = 'recentTrades'" @mouseleave="hoverDropdown = null">
          <button id="navbarButton" class="inline-flex items-center text-gold space-x-1 h-28 px-2 py-1">
            <span class="text-sm font-semibold font-poppins uppercase hover:text-dark-blue">Library</span>          
            <svg id="navbarSVG" class="h-5 text-gold hover:text-dark-blue transition-all duration-200" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" fill="currentColor" width="48">
                <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"/>
            </svg>
            <div v-if="hoverDropdown === 'recentTrades'" class="font-poppins text-left absolute bg-white py-2 w-50 z-40 shadow-lg top-20 max-h-80 overflow-y-auto">
              <a v-for="(item, index) in dropdownItems" :key="index" @click="fetchPDFLink(item.value)" href="#" class="block px-4 py-2 hover:text-dark-blue">{{ item.label }}</a>
            </div>
          </button>
        </a>

        <a href="/admin/manager" v-if="adminPriv">
          <button id="navbarButton" class="inline-flex items-center text-gold space-x-1 h-28 px-2 pr-6 py-1">
            <span class="text-sm font-semibold font-poppins uppercase hover:text-dark-blue">Admin Manager</span>
          </button>
        </a>
      </div>
    </div>
    <button @click="logout" class="text-white bg-dark-blue h-28 px-12 py-1 font-poppins uppercase">
      <span class="text-sm font-semibold">LOGOUT</span>
    </button>
  </nav>
</template>

<script>
import axios from '../../../axiosConfig'
import auth from '../../../auth';
import keycloak from '../../../keycloak';

export default {
  components: {
  },

  data() {
    return {
      hoverDropdown: null,
      adminPriv: null,
      managers: [],
      pdfLinks: {},
      dropdownItems: [
        { label: 'About Us', value: 'ABOUT' },
        { label: 'Investment Approach', value: 'APPROACH' },
        { label: 'Recent Trades', value: 'RECTRA' },
        { label: 'Market Outlook', value: 'OUTLOOK'},
        { label: 'Investment Insights', value: 'INSIGHTS'}
      ]
    }
  },
  async created() {
    const tokenParsed = keycloak.tokenParsed;
    if (tokenParsed && tokenParsed.realm_access && tokenParsed.realm_access.roles && tokenParsed.realm_access.roles.includes('Admin')) {
      this.adminPriv = true;
    }
    
    const { data: pdfLinks } = await axios.get(`/File?fileType=pdf`)
    const { data: managerResponse } = await axios.get(`/File?fileType=managers`)
    
    this.pdfLinks = pdfLinks;
    this.managers = Array.isArray(managerResponse) ? managerResponse : [];

    const pdfIsins = Object.keys(this.pdfLinks);

    this.managers = this.managers.filter(manager =>
      pdfIsins.includes(manager.row.Isin)
    );
  },

  methods: {
    logout() {
      this.$keycloak.logout();
      // auth.signOut();
    },
    async fetchPDFLink(isin) {  // fetchPDFLink take an isin parameter which represents the ISIN code of the pdf that the user wants to fetch      
      try {
        const response = await axios.get(`/File?filetype=pdf&isin=${isin}`);
        const pdfLink = response.data; // retrieves the pdf link from the response data

        window.open(pdfLink, '_blank'); // Redirect the user to the PDF link resulting in a download
      } catch (error) {
      }
    },
    async fetchManagerPDFLink(isin) {      
      try {
        const manager = this.managers.find(manager => manager.row.Isin === isin);
        if (!manager) {
          throw new Error('Manager not found');
        }

        const response = await axios.get(`/File?filetype=pdf&isin=${isin}`);
        const pdfLink = response.data; 

        window.open(pdfLink, '_blank'); 
      } catch (error) {
      }
    },
    homeClick() {
        this.$router.push(`/landing`);
      },
  }
}
</script>

<style scoped>
.hover\:bg-#97782c-20:hover {
  background-color: rgba(151, 120, 44, 0.2);
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #7C7878;
  border-radius: 4px;
}
</style>