<template>
  <MainLayout>
  <div class="mb-12">
    <div class="mb-36">
      <ManagerNavbar/>
    </div>
    <div class="container mx-auto flex flex-row items-center justify-between">
      <div class="flex flex-row items-center">
        <div>      
          <button @click="uploadFiles" class="flex flex-col items-center justify-center py-2 px-5">
            <img class="h-10 drop-shadow-xl" src="../../public/img/upload_button.svg" alt="Icon" />
            <p class="text-gold font-poppins font-bold text-xl ">Upload Files</p>
          </button>
        </div>
        <p class="text-gold text-2xl font-poppins font-bold mx-6">Upload Manager List</p>
        <div class="flex flex-row space-x-2 items-center mt-4 mb-2">
          <div class="relative">
            <select v-model="selectedOption" class="block appearance-none text font-poppins bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option selected disabled hidden>{{selectedOption}}</option>
              <option v-for="option in options" :key="option">{{option}}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M5.293 7.293L10 12l4.707-4.707L16.414 8l-6 6-6-6 1.414-1.414z"/>
              </svg>
            </div>
          </div>

          <div style="display: none;">
            <input type="file" ref="fileInput" @change="fileChosen" :accept="fileTypeAccept">
          </div>

          <div style="display: none;">
            <input type="file" ref="managerFileInput" @change="managerFileChosen" :accept="fileTypeAcceptManager">
          </div>

          <div class="flex flex-row space-x-2">
            <button 
              @click="uploadClicked" 
              class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
              {{ buttonText }}
            </button>
            <div class="flex flex-row items-center space-x-2">
                <img id="statusImage" v-show="csvUploadStatus !== ''" class="h-8" :src="images[csvUploadStatus]">
                <p id="uploadStatus" class="font-poppins font-bold" :class="csvUploadStatusClass">{{ csvUploadStatusMessage }}</p>
            </div>
          </div>
        </div>
        <p class="text-red-500 font-poppins mb-2" :style="{ 'display': fileTypeErrorMessage }">Please select a file type</p>
      </div>
      <button
        @click="managerUsers"
        class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[200px] py-2 px-4 rounded-md">
        Manage Users
      </button>
    </div>
    <table class="table-auto w-full container mx-auto font-poppins">
      <thead class="bg-dark-blue text-white ">
        <tr>
          <th class="px-6 py-4 font-normal">ISIN Code</th>
          <th class="px-6 py-4 font-normal">Description</th>
          <th class="px-6 py-4 font-normal">PDF Research Report Upload</th>
          <th class="px-6 py-4 font-normal">Asset Manager Logo Upload</th>
        </tr>
      </thead>
      <tbody class="text-dark-blue font-normal font-poppins text-center">
        <tr class="border-b-2" v-for="(manager, index) in managers" :key="index">
          <td class="px-4 py-2">{{ manager.row.Isin}}</td>
          <td class="px-4 py-2">{{ manager.row.Description}}</td>
          <td class="px-4 py-2">
            <span v-if="fileExists(manager.row.Isin, 'pdf')" class="flex flex-row space-x-2 justify-center">              
              <a @click="downloadFile(manager.row.Isin, 'pdf')" href="#" class="underline">Download PDF</a>
              <button @click="uploadManagerFile(manager.row.Isin, 'pdf')">
                <img class="h-6 w-auto" src="../../public/img/edit_button.svg">
              </button>
              <button @click="deleteManagerFile(manager.row.Isin, 'pdf')">
                <img class="h-6 w-auto" src="../../public/img/delete_button.svg">
              </button>              
            </span>
            <span v-else class="flex flex-row space-x-4 justify-center">
              <a href="#" @click="uploadManagerFile(manager.row.Isin, 'pdf')" class="underline">Upload PDF File</a>              
            </span>            
          </td>
          <td class="px-4 py-2">
            <span v-if="fileExists(manager.row.Isin, 'png')" class="flex flex-row space-x-2 justify-center">
              <a @click="downloadFile(manager.row.Isin, 'png')" href="#" class="underline">Download PNG</a>
              <button @click="uploadManagerFile(manager.row.Isin, 'png')">
                <img class="h-6 w-auto" src="../../public/img/edit_button.svg">
              </button>
              <button @click="deleteManagerFile(manager.row.Isin, 'png')">
                <img class="h-6 w-auto" src="../../public/img/delete_button.svg">
              </button>
            </span>
            <span v-else class="flex flex-row space-x-4 justify-center">
              <a href="#" @click="uploadManagerFile(manager.row.Isin, 'png')" class="underline">Upload PNG File</a>              
            </span>
          </td>
        </tr>
      </tbody>
    </table>  
  </div>
  <Footer/>
</MainLayout>
</template>

<script>
import ManagerNavbar from '../components/Atomic/AQuarks/ManagerNavbar.vue';
import Footer from '../components/Atomic/AQuarks/Footer.vue';
import { ref } from 'vue'
import auth from '../auth';
import axiosConfig from '../axiosConfig';
import axios from 'axios';
import MainLayout from '../components/Layouts/MainLayout.vue';

export default {
    name: 'ManagerAdmin',
    components: {
      ManagerNavbar,
      Footer,
      MainLayout
    },
    async created() {
      const managerResponse = await axiosConfig.get(`/File?filetype=managers`);
      const pdfLinks = await axiosConfig.get(`/File?fileType=pdf`);
      const pngLinks = await axiosConfig.get(`/File?fileType=png`);
      const getSignedPDFLinks = await axiosConfig.post(`/File?fileType=pdf`)
      const getSignedPNGLinks = await axiosConfig.post(`/File?fileType=png`)
      const parsedManagerData = managerResponse.data;
      this.managers = parsedManagerData;
      this.getSignedPDFLinks = getSignedPDFLinks;
      this.getSignedPNGLinks = getSignedPNGLinks
      this.pdfLinks = pdfLinks;
      this.pngLinks = pngLinks;

    },
    data() {
      return {
        options: [
          'Manager Admin Page'          
        ],
        selectedOption: 'Upload File',
        fileSelected: false,
        managerFileSelected: false,
        buttonText: 'Choose File',
        fileInput: null,
        managerFileInput: null,
        file: null,
        managerFile: null,
        signedLink: null,
        fileTypeAccept: '.csv',
        fileTypeAcceptManager: '',
        fileTypeErrorMessage: 'none',
        managers: null,
        csvUploadStatus: '', // 'loading', 'success', 'fail'
        images: {
          loading: '/img/loading.gif',
          success: '/img/checkmark.svg',
          fail: '/img/cancel.svg'
        }
      }
    },
    methods: {
      fileExists(isin, fileType) {
        if(fileType == 'pdf') {
          if(this.pdfLinks && this.pdfLinks.data.hasOwnProperty(isin)) {
            return true;
          }
        } else {
          if(this.pngLinks && this.pngLinks.data.hasOwnProperty(isin)) {
            return true;
          }
        }
        return false;
      },
      downloadFile(isin, fileType) {
        if(fileType == 'pdf') {
          if(this.pdfLinks && this.pdfLinks.data.hasOwnProperty(isin)) {
            window.open(this.pdfLinks.data[isin]);
          }
        } else {
          if(this.pngLinks && this.pngLinks.data.hasOwnProperty(isin)) {
            window.open(this.pngLinks.data[isin]);
          }
        }
        return null;
      },
      async uploadManagerFile(managerIsin, fileType) {
        this.fileTypeAcceptManager = '.' + fileType
        setTimeout(() => {
          this.signedLink = this.getManagerSignedLink(managerIsin, fileType);
          if(!this.managerFileSelected) {          
            this.managerFileInput.click();                    
          }      
        }, 1);
      },
      async deleteManagerFile(managerIsin, fileType) {
        let signedDeleteLink = await axiosConfig.delete(`/File?fileType=${fileType}&isin=${managerIsin}`)
        let deleteStatus = await axios.delete(signedDeleteLink.data)
        location.reload()
      },

      async uploadToS3(signedLink, managerFile) {
        try {
          let uploadStatus = await axios.put(signedLink, managerFile);
          location.reload()
        }catch(error) {
          console.error(error)
        }
      },
      getManagerSignedLink(managerIsin, fileType) {
        if(fileType == 'pdf'){
          if(this.getSignedPDFLinks && this.getSignedPDFLinks.data.hasOwnProperty(managerIsin)) {
            return this.getSignedPDFLinks.data[managerIsin];
          }
        } else {
          if(this.getSignedPNGLinks && this.getSignedPNGLinks.data.hasOwnProperty(managerIsin)) {
            return this.getSignedPNGLinks.data[managerIsin];
          }
        }
      },
      async uploadClicked() {
        if (this.selectedOption === 'Upload File') {
          this.fileTypeErrorMessage = 'block';
          this.csvUploadStatus = '';
          return;
        }
        else{
          this.csvUploadStatus = '';
          this.fileTypeErrorMessage = 'none';
        }
        if (!this.fileSelected) {
          this.fileInput.click();
        } else {
          this.csvUploadStatus = 'loading';
          let formData = new FormData();
          formData.append('file', this.$refs.fileInput.files[0]);
          let url = `/File?fileType=managers`
          const uploadResponse = await axiosConfig.post(url, formData)
          .then((response) => {
            this.csvUploadStatus = 'success';
            this.$refs.fileInput.value = ''; // Clear the file input
            this.fileSelected = false
            this.selectedOption = 'Upload File'
            this.buttonText = 'Choose File'
            location.reload()
          }).catch((error) => {
            this.csvUploadStatus = 'fail';
            this.$refs.fileInput.value = ''; // Clear the file input
            this.fileSelected = false
            this.selectedOption = 'Upload File'
            this.buttonText = 'Choose File'
          });
        }
      },
      managerUsers() {
        window.open("https://identity-admin.navistudios.co.za/admin/strategiq-prod/console/#/", "_blank");
      },
      uploadFiles() {
        this.$router.push(`/upload`).then(() => {
          window.scrollTo(0, 0);
        });
      },
      fileChosen(event) {
        if (event.target.files.length > 0) {
          this.file = event.target.files[0];
          this.fileSelected = true;
          this.buttonText = 'Upload';
        }
      },
      async managerFileChosen(event) {
        if (event.target.files.length > 0) {
          this.managerFile = event.target.files[0];
          this.managerFileSelected = true;
          let uploadResponse = await this.uploadToS3(this.signedLink, this.managerFile);
        }
      },
    },
    computed: {
      csvUploadStatusMessage() {
        switch(this.csvUploadStatus) {
            case 'loading': return 'Uploading...';
            case 'success': return 'Upload Successful!';
            case 'fail': return 'Upload Failed.';
            default: return '';
        }
      },
      csvUploadStatusClass() {
          switch(this.csvUploadStatus) {
              case 'loading': return 'text-dark-blue';
              case 'success': return 'text-[#5FB763]';
              case 'fail': return 'text-[#C52126]';
              default: return '';
          }
      },
    },
    mounted() {
      this.fileInput = this.$refs.fileInput;
      this.managerFileInput = this.$refs.managerFileInput;
    },
};
</script>