<template>
  <footer class="relative bottom-0 w-full bg-footer-beige inset-x-0 font-poppins text-dark-blue font-bold">
    <div class="container mx-auto py-2 px-4 flex flex-col items-center justify-center text-center">
      <p class="text-sm mb-2">Powered by</p>
      <img src="../../../../public/img/logo_footer.svg" alt="Logo" class="h-3 w-auto mb-2" />
      <p class="text-xs mb-2">
         An authorised Financial Services Provider (FSP:44624)
      </p>
      <p class="text-xs">
         © 2017 StrategiQ Capital (Pty) Ltd. All rights reserved
      </p>
    </div>
   </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>



