import { UserManager, User } from 'oidc-client';
import jwt_decode from 'jwt-decode';
import axios from 'axios';


const config = {
  authority: process.env.VUE_APP_OIDC_AUTHORITY,
  client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_OIDC_REDIRECT_URI, 
  response_type: process.env.VUE_APP_OIDC_RESPONSE_TYPE,
  scope: process.env.VUE_APP_OIDC_SCOPE,
  post_logout_redirect_uri: process.env.VUE_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true
};

const userManager: UserManager = new UserManager(config);

const signIn = async (): Promise<void> => {
  return userManager.signinRedirect();
};

const signOut = async (): Promise<void> => {
  return userManager.signoutRedirect();
};

interface DecodedToken {
  role: string;
  [key: string]: any;
}

const getUser = async (): Promise<{ user: User | null, role: string }> => {
  const user = await userManager.getUser();
  let role = "";

  if (user && user.id_token) {
    const decoded: DecodedToken = jwt_decode(user.id_token);
    role = decoded.groups[3] || "";
  }  
  return { user, role };
};



const handleCallback = async (): Promise<User> => {
  return await userManager.signinRedirectCallback();
};

const auth = {
  signIn,
  signOut,
  getUser,
  handleCallback,
};

export default auth;